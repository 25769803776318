﻿ // JS Dependencies: Popper, Bootstrap & JQuery
 import '@popperjs/core';
 import 'bootstrap';
 import 'jquery';
 // Using the next two lines is like including partial view _ValidationScriptsPartial.cshtml
 import 'jquery-validation';
 import 'jquery-validation-unobtrusive';
 // CSS Dependencies: Bootstrap & Bootstrap icons
 import 'bootstrap-icons/font/bootstrap-icons.css';
 import 'bootstrap/dist/css/bootstrap.css';

 // Fonts
 import '@GreatExpressions/npm-gedc-design-system/assets/fonts/AvenirLTStd-Black.woff2';
 import '@GreatExpressions/npm-gedc-design-system/assets/fonts/AvenirLTStd-Book.woff2';
 import '@GreatExpressions/npm-gedc-design-system/assets/fonts/AvenirLTStd-Heavy.woff2';
 import '@GreatExpressions/npm-gedc-design-system/assets/fonts/AvenirLTStd-Light.woff2';
 import '@GreatExpressions/npm-gedc-design-system/assets/fonts/AvenirLTStd-Medium.woff2';

 // Images
 import '@GreatExpressions/npm-gedc-design-system/assets/images/logos/GEDC_Horizontal_Color.svg';
 import '@GreatExpressions/npm-gedc-design-system/assets/images/logos/GEDC_Horizontal_White.svg';
 import '@GreatExpressions/npm-gedc-design-system/assets/images/logos/GEDC_Vertical_Black.svg';
 import '@GreatExpressions/npm-gedc-design-system/assets/images/logos/GEDC_Vertical_Color.svg';
 import '@GreatExpressions/npm-gedc-design-system/assets/images/logos/GEDC_Vertical_White.svg';
 import '@GreatExpressions/npm-gedc-design-system/assets/images/logos/GEDC_White_Icons.svg';
 import '@GreatExpressions/npm-gedc-design-system/assets/images/icons/fb.svg';
 import '@GreatExpressions/npm-gedc-design-system/assets/images/icons/glassdoor.svg';
 import '@GreatExpressions/npm-gedc-design-system/assets/images/icons/instagram.svg';
 import '@GreatExpressions/npm-gedc-design-system/assets/images/icons/linkedin.svg';
 import '@GreatExpressions/npm-gedc-design-system/assets/images/icons/youtube.svg';
 import '@GreatExpressions/npm-gedc-design-system/assets/images/CareCredit.svg';
 import '@GreatExpressions/npm-gedc-design-system/assets/images/HomePage.svg';
 import '@GreatExpressions/npm-gedc-design-system/assets/images/SocialMedia.svg';
 import '@GreatExpressions/npm-gedc-design-system/assets/images/SPP.svg';

 // Custom CSS imports
 import '@GreatExpressions/npm-gedc-design-system/assets/css/gedc-styles.css';
 import '../css/site.css';
